onLoad(($) => {
  const nodes = $('.JS-private-fixed-income-ted');

  if (!nodes.length) return;

  const refreshFields = function(radio, pageLoaded) {
    const selected = $(radio).filter(':checked').val();

    if(selected == "ted_tef") {
      showSameTitularity();
    } else {
      hideSameTitularity();
    };
  };

  const hideSameTitularity = function() {
    const yes = $("#ticket_private_fixed_income_counter_part_attributes_same_ownership_yes")
    const no  = $("#ticket_private_fixed_income_counter_part_attributes_same_ownership_no")

    no.trigger('click');

    yes.hide().parent().hide().parent().hide();
    no.hide().parent().hide().parent().hide();
  };

  const showSameTitularity = function() {
    const yes = $("#ticket_private_fixed_income_counter_part_attributes_same_ownership_yes")
    const no  = $("#ticket_private_fixed_income_counter_part_attributes_same_ownership_no")

    yes.show().parent().show().parent().show();
    no.show().parent().show().parent().show();
  };

  nodes.change(function(e) {
    refreshFields(this, false);
  });

  for (let i = 0;i < nodes.filter(':checked').length; i++) {
    refreshFields(nodes.filter(':checked')[i], true);
  }
});
