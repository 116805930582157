onLoad(($) => {
  const nodes = $('.JS-margin-filter-fields');

  if (!nodes.length) return;

  const refreshFields = function(radio, pageLoaded) {
    const selected = $(radio).filter(':checked').val();

    if(selected == "public_fixed_income") {
      $('.public *').prop('disabled', false).show();
      $('.private *').prop('disabled', true).hide();
    } else if(selected == "private_fixed_income") {
      $('.public *').prop('disabled', true).hide();
      $('.private *').prop('disabled', false).show();
    }
    else {
      $('.public *').prop('disabled', true).hide();
      $('.private *').prop('disabled', true).hide();
    };
  };

  nodes.change(function() {
    refreshFields(this);
  });

  for (let i = 0;i < nodes.filter(':checked').length; i++) {
    refreshFields(nodes.filter(':checked')[i]);
  }
});
