onLoad(($) => {
  const nodesPassive = $('.JS-invested-funds-passive');
  const nodesAccountPassive = $('.JS-account-passive');
  var settlement_type = $('input[name="ticket_quotum[settlement_type]"]:checked').val();

  // Selecina o banco 363 quando o fundo for interno (SOCOPA)
  $(document).ready(function() {
    const operationTypeRadios = document.querySelectorAll('input[name="ticket_passive_quotum[operation_type]"]');
    const settlementTypeRadios = document.querySelectorAll('input[name="ticket_passive_quotum[settlement_type]"]');
    const bankAccountSelect = document.getElementById('ticket_passive_quotum_counter_part_attributes_bank_account_id');     
    const fundBankAccountSelect = document.getElementById('ticket_passive_quotum_fund_account');
    const fundCodeSelect = document.getElementById('ticket_passive_quotum_fund_code');
    const AccountCetipSelect = document.getElementById('ticket_passive_quotum_counter_part_attributes_cetip_account');
    const txtDangerAccount = document.getElementById('txt-danger-account');
    const OperationValue = document.getElementById('ticket_passive_quotum_formatted_operation_value');
    let bankAccountParent = null;
    let OperationValueParent = null; 

    let operationTypeSelectedElement = document.querySelector('input[name="ticket_passive_quotum[operation_type]"]:checked');
  
    if (operationTypeSelectedElement) {
      const operationTypeSelected = operationTypeSelectedElement.value;
  
      if (bankAccountSelect instanceof HTMLSelectElement) {
        bankAccountParent = bankAccountSelect.parentElement;
  
        if (operationTypeSelected === 'application') {
          if (bankAccountParent != null) {
            bankAccountParent.style.display = 'none';
          }
        }
      }
    }

    function removeAllOptions(selectElement) {
      if (selectElement instanceof HTMLSelectElement) {
        while (selectElement.options.length > 0) {
            selectElement.remove(0);
        }
      }
    }
    function toggleBankAccountSelect() {

      if (bankAccountSelect instanceof HTMLSelectElement) {
        bankAccountParent = bankAccountSelect.parentElement;
      }
        OperationValueParent = OperationValue.parentElement;
      
        const selectedOperationType = document.querySelector('input[name="ticket_passive_quotum[operation_type]"]:checked').value;
        const selectedSettlementType = document.querySelector('input[name="ticket_passive_quotum[settlement_type]"]:checked').value;

        if (selectedSettlementType === 'ted_tef' && (selectedOperationType === 'redeem' || selectedOperationType === 'full_redeem')) {
          txtDangerAccount.style.display = 'block';
          
          if(bankAccountParent != null)
          bankAccountParent.style.display = 'block';

        } 
        else if (selectedSettlementType === 'ted_tef' && selectedOperationType === 'application'){
          txtDangerAccount.style.display = 'none';

          if(bankAccountParent != null)
            bankAccountParent.style.display = 'none';
        }
        
        else {
          if(bankAccountParent != null)
            bankAccountParent.style.display = 'none';

          txtDangerAccount.style.display = 'block';
        }

        if (selectedOperationType === 'full_redeem'){
            OperationValueParent.style.display = 'none';
        }
        else {
          OperationValueParent.style.display = 'block';
          OperationValue.value = null
        }
        

        removeAllOptions(bankAccountSelect);
        removeAllOptions(fundBankAccountSelect);
        removeAllOptions(fundCodeSelect);

        AccountCetipSelect.value = ''

        bankAccountParent = null;
        OperationValueParent = null; 
        
    }

    operationTypeRadios.forEach(radio => {
        radio.addEventListener('change', toggleBankAccountSelect);
    });

    settlementTypeRadios.forEach(radio => {
        radio.addEventListener('change', toggleBankAccountSelect);
    });

    // Inicializar a exibição correta no carregamento da página
    // toggleBankAccountSelect();
  });

  if (!nodesPassive.length) return;

  const searchBankAccounts = (fundId, url, field, filters = null) => {
    
    if (!!filters) {
      url = `${url}?${filters}`
    }

    $(`${field}`).find('option').remove().end();

    $.ajax({
      url: url,
      type: 'GET',
      data: { fund_id: fundId, is_active: true },
      success: function (response) {

        // Verificando se a resposta é um array
        if(Array.isArray(response)) {

          // Iterando sobre cada elemento do array
          for (let x = 0; x < response.length; x++) {

            // Acessando os campos 'text' e 'id' de cada objeto
            const text = response[x].text;
            const id = response[x].id;

            // Adicionando uma opção ao elemento field
            $(`${field}`).append($("<option />").val(id).text(text));
          }
          // Disparando o evento 'change' no elemento field após adicionar as opções
          $(`${field}`).trigger('change');
        } else {
          console.error("A resposta não é um array.");
        }
      }
    });
  };

  nodesPassive.each((_index, element) => {
    const fundsPath = element.dataset.fundsPath;
    const bankAccountsPath = element.dataset.fundAccountsPath;
    const fundBankAccountField = element.dataset.fundAccountField;
    const accountType = element.dataset.accountType;
    const quotationDate = element.dataset.quotationDate;
    const settlementDate = element.dataset.settlementDate;
    const fundAssetCodeCetip = element.dataset.fundAssetCodeCetip;
    const fundAccountCetip = element.dataset.fundAccountCetip;
    const fundBank = element.dataset.fundBank;
    const fundAgency = element.dataset.fundAgency;
    const fundAccount = element.dataset.fundAccount;
    const condominium = element.dataset.condominiumCd

    $(element).select2({
      ajax: {
        url: fundsPath,
        dataType: 'json',
        data: function (params) {
          var queryParameters = {
            q: params.term,
            select_2_extra: accountType,
            operationType: $('input[name="ticket_passive_quotum[operation_type]"]:checked').val(),
            settlementType: $('input[name="ticket_passive_quotum[settlement_type]"]:checked').val(),
            document: $('input[name="ticket_passive_quotum[shareholder_document_number]"]').val()
            
          }

          return queryParameters;
        }
      }
    });

    $(element).on('select2:selecting', function(e) {
      const operationType = $('input[name="ticket_passive_quotum[settlement_type]"]:checked').val();
  
      if (operationType == 'application') {
        $(quotationDate).val(e.params.args.data.quotation_date_application);
        $(settlementDate).val(e.params.args.data.settlement_date_application);
      } else {
        $(quotationDate).val(e.params.args.data.quotation_date_redeem);
        $(settlementDate).val(e.params.args.data.settlement_date_redeem);
      }

      $(fundAssetCodeCetip).val(e.params.args.data.fund_asset_code_cetip);
      $(fundAccountCetip).val(e.params.args.data.fund_account_cetip);
      $(fundBank).val(e.params.args.data.bank);
      $(fundAgency).val(e.params.args.data.agency);
      $(fundAccount).val(e.params.args.data.account);
      $(condominium).val(e.params.args.data.is_cff);
    });

    $(element).on('change', function() {        
      const selectedId = $(this).val();

      // Verificando se há um ID selecionado
      if(selectedId) {
          const selectedData = $(this).select2('data')[0];
          const fundIdValue = selectedData.fund_id;
          if(fundIdValue) {
              searchBankAccounts(fundIdValue, bankAccountsPath, fundBankAccountField, null);
          }
      }
    });
  });

});