onLoad(($) => {
  const nodes = $('.JS-filter-cetip-assets-fields');

  if (!nodes.length) return;

  const elements = $('[name="cetip_assets[]"]')
  const assets = [];
  const asset_type_select = $('#ticket_private_fixed_income_asset_type_cd');
  const asset_code_select = $('#ticket_private_fixed_income_asset_code');
  const expiration_date_select = $('#ticket_private_fixed_income_due_date');
  const current_asset_code = $('#ticket_asset_code').val();
  const current_due_date = $('#ticket_due_date').val();

  const cleanFields = function () {
    asset_code_select.val(null);
    expiration_date_select.val(null);
  }

  const appendOptions = function (node, values) {
    values.forEach(val => {
      var newOption = new Option(val, val, false, false);
      node.append(newOption).trigger('change');
    });
  }

  const formatDate = function (date) {
    date = new Date(date);
    let dia = date.getDate().toString();
    let diaF = (dia.length == 1) ? '0' + dia : dia;
    let mes = (date.getMonth() + 1).toString();
    let mesF = (mes.length == 1) ? '0' + mes : mes;
    let anoF = date.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  }

  for (let i = 0; i < elements.length; i++) {
    assets.push(JSON.parse($(elements[i]).val()));
  }


  asset_type_select.on('change', function (e) {
    cleanFields();
    asset_code_select.html("");
    expiration_date_select.html("");
    const asset_codes = new Set(assets.filter(el => el.asset_type == e.currentTarget.value).map(el => el.asset_code));
    appendOptions(asset_code_select, asset_codes);
  });

  asset_code_select.on('change', function (e) {
    expiration_date_select.html("");
    const selected_asset_type = asset_type_select.val();
    const expiration_dates = new Set(assets
      .filter(el => el.asset_code == e.currentTarget.value && el.asset_type == selected_asset_type)
      .sort((a, b) => new Date(a.expiration_date) - new Date(b.expiration_date))
      .map(el => formatDate(el.expiration_date)));
    appendOptions(expiration_date_select, expiration_dates);
  });

  if (asset_type_select.val()) {
    asset_type_select.trigger('change');
    asset_code_select.val(current_asset_code);
    asset_code_select.trigger('change');
    expiration_date_select.val(current_due_date);
  }
});
