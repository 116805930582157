onLoad(($) => {
  const nodes = $('.JS-quota-sovereign');

  if (!nodes.length) return;

  const refreshFields = function(radio, pageLoaded) {
    const selected = $(radio).filter(':checked').val();
    if(pageLoaded === false) {
      $('#ticket_quotum_fund_id').val(null).trigger('change');
    }

    if(selected == "ted_tef") {
      showSameTitularity();
    } else {
      hideSameTitularity();
    };

    if(selected == "sovereign") {     
      const santanderName           = radio.dataset.santanderName;
      const santanderId             = radio.dataset.santanderId;
      const santanderQuotationDate  = radio.dataset.quotationDate;
      const santanderSettlementDate = radio.dataset.settlementDate;

      fillSovereign();
    } else if (pageLoaded === false) {
      unfillSovereign();
    }
  };

  const hideSameTitularity = function() {
    const yes = $("#ticket_quotum_counter_part_attributes_same_ownership_yes")
    const no  = $("#ticket_quotum_counter_part_attributes_same_ownership_no")

    no.trigger('click');

    yes.hide().parent().hide().parent().hide();
    no.hide().parent().hide().parent().hide();
  };

  const showSameTitularity = function() {
    const yes = $("#ticket_quotum_counter_part_attributes_same_ownership_yes")
    const no  = $("#ticket_quotum_counter_part_attributes_same_ownership_no")

    yes.show().parent().show().parent().show();
    no.show().parent().show().parent().show();
  };

  const unfillSovereign = function() {
    const newInvestedfund   = $('#ticket_quotum_new_invested_fund');
    const select            = $('#ticket_quotum_invested_fund_id');
    const condominiumOpen   = $('#ticket_quotum_condominium_quotum_open');
    const condominiumClosed = $('#ticket_quotum_condominium_quotum_closed');
    const isFidcYes         = $("#ticket_quotum_is_fidc_yes");
    const isFidcNo          = $("#ticket_quotum_is_fidc_no");
    const counterPartForm   = $("#counter_part_form")

    select.empty();

    newInvestedfund.prop('checked', true).removeAttr('readonly').removeAttr('disabled').trigger('click');
    select.removeAttr('readonly').removeAttr('disabled');

    condominiumClosed.removeAttr('readonly').removeAttr('disabled');
    condominiumOpen.removeAttr('readonly').removeAttr('disabled');
    isFidcNo.removeAttr('readonly').removeAttr('disabled').trigger('click');
    isFidcYes.removeAttr('readonly').removeAttr('disabled');
    counterPartForm.show();
    counterPartForm.find('input').removeAttr('readonly').removeAttr('disabled');

    $('.ticket_quotum_new_invested_fund').show();
  };

  const fillSovereign = function(name, id, quotationDate, settlementDate) {
    const newInvestedfund     = $('#ticket_quotum_new_invested_fund');
    const quotationDateField  = $('#ticket_quotum_quotation_date');
    const settlementDateField = $('#ticket_quotum_settlement_date');
    const condominiumOpen     = $('#ticket_quotum_condominium_quotum_open');
    const condominiumClosed   = $('#ticket_quotum_condominium_quotum_closed');
    const isFidcYes           = $("#ticket_quotum_is_fidc_yes");
    const isFidcNo            = $("#ticket_quotum_is_fidc_no");
    const counterPartForm     = $("#counter_part_form")

    newInvestedfund.prop('checked', true).trigger('click').attr({ readonly: 'readonly', disabled: true });
    $('.ticket_quotum_new_invested_fund').hide();

    quotationDateField.val(quotationDate);
    settlementDateField.val(settlementDate);

    condominiumOpen.trigger('click');
    condominiumClosed.attr({ disabled: true });
    condominiumOpen.attr({ disabled: true });
    isFidcNo.trigger('click').attr({ readonly: 'readonly', disabled: true });
    isFidcYes.attr({ readonly: 'readonly', disabled: true });

    counterPartForm.hide();
    counterPartForm.find('input').attr({ readonly: 'readonly', disabled: true });
  }

  nodes.change(function(e) {
    refreshFields(this, false);
  });

  for (let i = 0;i < nodes.filter(':checked').length; i++) {
    refreshFields(nodes.filter(':checked')[i], true);
  }
});
