onLoad(($) => {
  $('.card').on('click', '.fa-plus-square', function show() {
    $(this).parent('.card-header').parent('.card').find('.card-body')
      .show('slow');
    $(this).removeClass('fa-plus-square').addClass('fa-minus-square');
  });

  $('.card').on('click', '.fa-minus-square', function hide() {
    $(this).parent('.card-header').parent('.card').find('.card-body')
      .hide('slow');
    $(this).removeClass('fa-minus-square').addClass('fa-plus-square');
  });
});
