onLoad(($) => {
  const nodes = $('.JS-invested-funds');
  var settlement_type = $('input[name="ticket_quotum[settlement_type]"]:checked').val();

  // Selecina o banco 363 quando o fundo for interno (SOCOPA)
  $(document).ready(function() {
    $('#ticket_quotum_invested_fund_id').change(function() {
      if ($(this).find('option:selected').text().includes('Administrador: SOCOPA')) {
        $('#ticket_quotum_counter_part_attributes_bank_account_attributes_bank_code').one('click', function() {
          const selectedOption = $(this).find('option:contains("363")');
          selectedOption.prop('selected', true);
  
          const title = selectedOption.text();
          const text = selectedOption.text().split(" - ")[1];
  
          const spanHtml = '<span class="select2-selection__rendered" id="select2-ticket_quotum_counter_part_attributes_bank_account_attributes_bank_code-container" role="textbox" aria-readonly="true" title="' + "363 - " + title + '">' + "363 -"  + text + '</span>';
          
          $(this).next('.select2-container').find('.select2-selection').html(spanHtml);
        }).click();
      }
    });
  });

  if (!nodes.length) return;

  nodes.each((_index, element) => {
    const fundsPath = element.dataset.fundsPath;
    const accountType = element.dataset.accountType;
    const quotationDate = element.dataset.quotationDate;
    const settlementDate = element.dataset.settlementDate;
    const sovereign = $('input[name="ticket_quotum[settlement_type]"]').change((e) => {
      settlement_type = e.target.value,
      $(element).select2({
        ajax: {
          url: fundsPath,
          dataType: 'json',
          data: function (params) {
            var queryParameters = {
              q: params.term,
              settlement_type : settlement_type,
              select_2_extra: accountType
            }
  
            return queryParameters;
          }
        }
      });
    })


    $(element).select2({
      ajax: {
        url: fundsPath,
        dataType: 'json',
        data: function (params) {
          var queryParameters = {
            q: params.term,
            select_2_extra: accountType,
            settlement_type : settlement_type,
            
          }

          return queryParameters;
        }
      }
    });

    $(element).on('select2:selecting', function(e) {
      const operationType = $('input[name="ticket_quotum[operation_type]"]:checked').val();
  
      if (operationType == 'application') {
        $(quotationDate).val(e.params.args.data.quotation_date_application);
        $(settlementDate).val(e.params.args.data.settlement_date_application);
      } else {
        $(quotationDate).val(e.params.args.data.quotation_date_redeem);
        $(settlementDate).val(e.params.args.data.settlement_date_redeem);
      }

    });
  });
});
