onLoad(($) => {

  const nodesShareholderAccountPassive = $('.JS-shareholder-account-passive');
  var settlement_type = $('input[name="ticket_quotum[settlement_type]"]:checked').val();


  if (!nodesShareholderAccountPassive.length) return;
  
  nodesShareholderAccountPassive.each((_index, element) => {
    const shareholderAccountsPath = element.dataset.shareholderAccountsPath;
    const shareholderDocumentField = element.dataset.shareholderDocument;


    $(element).select2({
      ajax: {
        url: shareholderAccountsPath,
        dataType: 'json',
        data: function (params) {
          var queryParameters = {
            q: params.term,
            shareholder_document: $('input[name="ticket_passive_quotum[shareholder_document_number]"]').val()
          }

          return queryParameters;
        }
      }
    });
  });
});