onLoad(($) => {
  const nodes = $('.JS-calculate-value-in-purchase-commitment');

  if (!nodes.length) return;

  const calculateSingle = (quantity, value, field) => {
    let floatValue = parseFloat(value.val().replaceAll('.', '').replaceAll(',', '.'));
    let decimalPlaces = floatValue.toString().split('.')[1] ? floatValue.toString().split('.')[1].length : 2
    const calculatedValue = parseFloat(quantity) * floatValue;
    const formattedValue = calculatedValue.toLocaleString("pt-BR",
      {style:"currency", currency:"BRL", maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces});

    field.val(formattedValue);
  }

  const calculate = (quantity, goingValue, returnValue, goingResult, returnResult, operationResult) => {
    if(quantity !== "" && goingValue.val() != "") {
      calculateSingle(quantity, goingValue, goingResult);

      operationResult.val(goingResult.val());
    } else {
      goingResult.val("");
      operationResult.val("");
    }

    if(quantity !== "" && returnValue.val() != "") {
      calculateSingle(quantity, returnValue, returnResult);
    } else {
      returnResult.val("");
    }
  };

  for (let i = 0;i < nodes.length;i = i + 1) {
    const node = nodes[i]

    const operationResult = $(`#${node.dataset.operationResult}`);
    const goingValue      = $(`#${node.dataset.goingValue}`);
    const returnValue     = $(`#${node.dataset.returnValue}`);
    const goingResult     = $(`#${node.dataset.goingResult}`);
    const returnResult    = $(`#${node.dataset.returnResult}`);

    calculate($(node).val(), goingValue, returnValue, goingResult, returnResult, operationResult);

    $(node).on('change keyup', function() {
      calculate($(node).val(), goingValue, returnValue, goingResult, returnResult, operationResult);
    });

    goingValue.on('change keyup', function() {
      calculate($(node).val(), goingValue, returnValue, goingResult, returnResult, operationResult);
    });

    returnValue.on('change keyup', function() {
      calculate($(node).val(), goingValue, returnValue, goingResult, returnResult, operationResult);
    });
  };
});
