$(document).on("click", "#requestreport", function() {
  var botao = $(this);
  var divform = $("#form_tickets");
  var divsearch = $("#search_form");

  if (divform.is(":hidden")) {
    divform.show();
    divsearch.hide();
    botao.text("Solicitar Relatório");
  } else {
    divform.hide();
    botao.text("Solicitar Relatório");
  }
});


$(document).on("click", "#filterreport", function() {
  var botao = $(this);
  var divsearch = $("#search_form");
  var divform = $("#form_tickets");

  if (divsearch.is(":hidden")) {
    divsearch.show();
    divform.hide();
    botao.text("Filtrar Relatório"); 
  } else {
    divsearch.hide();

    botao.text("Filtrar Relatório");
  }
});
