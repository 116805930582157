onLoad(($) => {
  const nodes = $('.JS-transfer-funds');

  if (!nodes.length) return;

  const updateToBankAccount = function(select) {
    const selected = $(select).children('option:selected')[0];

    if(!selected)
      return;

    const toBankAccountField = $("#ticket_transfer_to_bank_account_id");
    const availableBankAccounts = $(select).children('option').not(selected);

    toBankAccountField.empty();

    if(availableBankAccounts.length >= 1) {
      for (let i = 0;i < availableBankAccounts.length; i++) {
        let current = availableBankAccounts[i];

        toBankAccountField.append($("<option></option>").attr("value", current.value).text(current.text));
      }
    } else {
      console.log('Precisa ter mais de uma conta.');
    }
  };

  nodes.on('change', function() {
    updateToBankAccount(this);
  });
})
