onLoad(($) => {
  const nodes = $('.JS-calculate-value-by-tax');

  if (!nodes.length) return;

  const calculate = (quantity, valueField, field) => {
    if(quantity !== "" && valueField.val() !== "") {
      let floatValue = parseFloat(valueField.val().replaceAll(',', '.'));
      let decimalPlaces = floatValue.toString().split('.')[1] ? floatValue.toString().split('.')[1].length : 2
      const calculatedValue = parseFloat(quantity) * floatValue;

      field.val(calculatedValue.toFixed(decimalPlaces).replace('.', ','));
    } else {
      field.val("");
    }
  };

  for (let i = 0;i < nodes.length;i = i + 1) {
    const node = nodes[i]
    const valueId = $(`#${node.dataset.valueId}`);
    const resultId = $(`#${node.dataset.resultId}`);

    calculate($(node).val(), valueId, resultId);

    $(node).on('change keyup', function() {
      calculate($(this).val(), valueId, resultId);
    });

    valueId.on('change keyup', function() {
      calculate($(node).val(), $(this), resultId);
    });
  };
});
