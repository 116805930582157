onLoad(($) => {
  const nodes = $('.JS-show-cetip-voice-id');
  $('.ticket_quotum_counter_part_cetip_voice_identification').hide();
  $('.ticket_quotum_counter_part_cetip_voice').hide();

  if (!nodes.length) return;

  $('input[type=radio]').change(function() {
    var condominium = $('input[name="ticket_quotum[condominium_quotum]"]:checked').val();
    var secondary_market = $('input[name="ticket_quotum[secondary_market]"]:checked').val();
    var settlement_type = $('input[name="ticket_quotum[settlement_type]"]:checked').val();
    if (condominium == 'closed' && secondary_market == 'yes' && settlement_type == 'cetip') {
      $('.ticket_quotum_counter_part_cetip_voice_identification').show();
      $('.ticket_quotum_counter_part_cetip_voice').show();
      $('#ticket_quotum_counter_part_attributes_cetip_voice').prop('disabled', true);
      $('#ticket_quotum_counter_part_attributes_cetip_voice').prop('checked', true);
    } else {
      $('.ticket_quotum_counter_part_cetip_voice_identification').hide();
      $('.ticket_quotum_counter_part_cetip_voice_identification').val('');
      $('.ticket_quotum_counter_part_cetip_voice').hide();
      $('#ticket_quotum_counter_part_attributes_cetip_voice').prop('checked', false);
    };
  })
});
