onLoad(($) => {
  const nodes = $('.JS-cetip-voice');

  if (!nodes.length) return;

  const settlementRadio = $('.JS-settlement-radio');
  const assetsWithCetipVoice = ['cra', 'cri', 'cdca', 'deb'];

  const refreshRadio = function(node) {
    const settlementType = $('input[name="ticket_private_fixed_income[settlement_type]"]:checked').val();
    const field = $('#ticket_private_fixed_income_is_cetip_voice');
    const assetTypeValue = $('#ticket_private_fixed_income_asset_type').children('option:selected').val();

    if (settlementType == 'cetip' && field.length > 0 && assetsWithCetipVoice.includes(assetTypeValue)) {
      $('#ticket_private_fixed_income_is_cetip_voice').removeAttr('readonly').removeAttr('disabled').show().parent().show();
    } else if (field.length > 0) {
      $('#ticket_private_fixed_income_is_cetip_voice').attr({ readonly: 'readonly', disabled: true }).hide().parent().hide();
    }
  }

  const refreshFields = function(node) {
    const settlementType = $('input[name="ticket_private_fixed_income[settlement_type]"]:checked').val();
    const $node = $(node);
    const newValue = $node.children('option:selected').val();

    if (assetsWithCetipVoice.includes(newValue) && settlementType == 'cetip') {
      $('#ticket_private_fixed_income_is_cetip_voice').removeAttr('readonly').removeAttr('disabled').show().parent().show();
    } else {
      $('#ticket_private_fixed_income_is_cetip_voice').attr({ readonly: 'readonly', disabled: true }).hide().parent().hide();
    }
  }

  settlementRadio.change(function() {
    refreshRadio(this);
  });

  for (let i = 0; i < settlementRadio.filter(':checked').length; i++) {
    refreshRadio(settlementRadio.filter(':checked')[i]);
  }

  nodes.change(function() {
    refreshFields(this);
  });

  for (let i = 0;i < nodes.filter(':checked').length; i++) {
    refreshFields(nodes.filter(':checked')[i]);
  }
});
