onLoad(($) => {
  $(document).ready(function() {

    $(".funds-cutoff").prop("disabled", true);
    $(".managers-cutoff").on("change", function() {

      const selectedValues = $(this).val();
      if (selectedValues && selectedValues.length > 0) {
        $(".funds-cutoff").prop("disabled", false);
      } else {
        $(".funds-cutoff").prop("disabled", true);
      }
    });
  });


  const nodes = $(".managers-cutoff");
  let payload = [];
  let manager_id = ""
  let funds_default = []

  if (!nodes.length) return;
  let managers = [];
  nodes
    .each((_index, element) => {
      const managersPath = element.dataset.managersPath;
      const accountType = element.dataset.accountType;
      const manager = element.dataset.manager;

      $(element).select2({
        ajax: {
          url: managersPath,
          dataType: "json",
          data: function (params) {

            const queryParameters = {
              q: params.term,
              select_2_extra: accountType,
            };
            return queryParameters;
          },
          success: function (data) {
            managers = data.results;
          },
        },
      });

      if (managers.length <= 0) {
        $.ajax({
          url: managersPath,
          dataType: "json",
          data: {
            select_2_extra: accountType,
          },
          success: function (data) {
            managers = data.results;
          },
        });
      }

      if (manager != "null" && manager != null && manager !== undefined) {
        const json = JSON.parse(manager);
        const name = json["name"] + " - " + json["document_number"];
        const id = parseInt(json["id"]);
        $(element).select2("trigger", "select", {
          data: { id: id, text: name },
        });
      }
    })
    .on("change", function () {
      selectedValue = $(this).val();
      manager_id = selectedValue;
    });

  const nodesFunds = $(".funds-cutoff");

  if (!nodesFunds.length) return;

  nodesFunds.each((_index, element) => {
    const fundsPath = element.dataset.fundsPath;
    const accountType = element.dataset.accountType;
    const fund = element.dataset.fund;

    const $select = $(
      "#cutoff_extra_fund_id, #temporary_cutoff_fund_id"
    ).select2({
      ajax: {
        url: fundsPath,
        dataType: "json",
        data: function (params) {
          const queryParameters = {
            q: params.term,
            select_2_extra: accountType,
            manager_id: manager_id || null,
          };
          return queryParameters;
        },
        processResults: function (data) {
          const options = data.results.map(function (fund) {
            return {
              id: fund.id,
              text: fund.text,
              manager_id: fund.manager_id,
            };
          });

          const optionsGrouped = {};

          options.forEach(function (option) {
            if (!optionsGrouped[option.manager_id]) {
              optionsGrouped[option.manager_id] = {
                text: getManagerName(option.manager_id) + option.manager_id,
                children: [],
              };
            }

            optionsGrouped[option.manager_id].children.push(option);
          });

          function getManagerName(manager_id) {

            const name = managers.find((i) => i.id === manager_id);
            return name?.text;
          }

          const groups = Object.values(optionsGrouped);

          $("#btn-submit, #btn-solicitar-cutoff").click(function (e) {
            e.preventDefault();

            const managers = $("#cutoff_extra_manager_id, #temporary_cutoff_manager_id").val();
            const funds = $("#cutoff_extra_fund_id, #temporary_cutoff_fund_id").val();

            const managerFunds = {};

            if (managers.length > 0) {
              groups.forEach((item) => {
                payload.push(item.children);
              });

              for (let i = 0; i < payload.length; i++) {
                const fundArray = payload[i];
                for (let j = 0; j < fundArray.length; j++) {
                  const fund = fundArray[j];
                  if (managerFunds[fund.manager_id]) {
                    managerFunds[fund.manager_id].funds.push(fund.id);
                  } else {
                    managerFunds[fund.manager_id] = {
                      manager_id: fund.manager_id,
                      funds: [fund.id],
                    };
                  }
                }
              }
              let managerFundsArray = Object.values(managerFunds);

              const arrayToNumber = funds.map(Number)
              for (let a = 0; a < managerFundsArray.length; a++) {
                managerFundsArray[a].funds = managerFundsArray[a].funds.filter(fund => arrayToNumber.includes(fund))
              }


              payload = managerFundsArray;
            } else {
              payload = funds
            }
          });
          return { results: groups };
        },
        templateResult: function (data) {
          if (!data.id) {
            return data.text;
          }

          return $("<span>" + getManagerName(data.text) + "</span>");
        },
        templateSelection: function (data, container) {
          return $("<span>" + getManagerName(data.text) + "</span>");
        },
      },
    });

    $select.on("select2:select", function (event) {
      const selectedData = event?.params?.data;
    });

    if (fund != "null" && fund != undefined && fund != null) {
      const json = JSON.parse(fund);
      const name = json["name"] + " - " + json["document_number"];
      const id = parseInt(json["id"]);
      $(element).select2("trigger", "select", {
        data: { id: id, text: name },
      });
    }
  });

  $("#btn-submit, #btn-solicitar-cutoff").click(function (e) {
    e.preventDefault();
    setTimeout(() => {
      const time_limit = $("#cutoff_extra_time_limit, #temporary_cutoff_time_limit").val()
      const active_cd = $("#active_cd_cutoff").prop("checked");
      const justification = $("#cutoff_extra_justification, #temporary_cutoff_justification").val()

      const managers = $("#cutoff_extra_manager_id, #temporary_cutoff_manager_id").val();
      const funds = $("#cutoff_extra_fund_id, #temporary_cutoff_fund_id").val();

      if (managers.length > 0 && funds.length <=0) {

        payload = []
        for (let i = 0; i < managers.length; i++) {
          payload.push({
            manager_id: managers[i]
          })
        }

      }else if (managers.length > 0){
        // Remover objetos com manager_id que não estão em managers
        payload.filter(item => managers.includes(item.manager_id.toString()));

        // Adicionar objetos com manager_id que estão em idsToCheck, mas não estão em data
        managers.forEach(id => {
          const existingItem = payload.find(item => item.manager_id === parseInt(id));
          if (!existingItem) {
            const newItem = {
              manager_id: parseInt(id),
              funds: []
            };
            payload.push(newItem);
          }
        });
      }
      
      const managersFunds = JSON.stringify(payload);
      console.log(managersFunds);
      const currentRoute = window.location.pathname.replace(/\/new$/, "");
      $.ajax({
        url: currentRoute,
        type: "POST",
        data: JSON.stringify({ manager_funds: payload, time_limit, justification, active_cd  }),
        contentType: "application/json",
        success: function(data) {

        },
        error: function(data) {
         
          response = data.responseJSON
            var errorsHtml = "<ul>";
            if (response){
              $.each(response, function(index, error) {
                if (error.time_limit) {
                  errorsHtml += "<li class=\"text-danger\">Horário Limite: " + error.time_limit + "</li>";
                }
                if (error.fund_id) {
                  errorsHtml += "<li class=\"text-danger\">Fundo: " + error.fund_id + "</li>";
                }
                if (error.manager_id) {
                  errorsHtml += "<li class=\"text-danger\">Gestor: " + error.manager_id + "</li>";
                }
                if (error.justification) {
                  errorsHtml += "<li class=\"text-danger\">Justificativa: " + error.justification+ "</li>";
                }
              });
            }else{
              errorsHtml += "<li class=\"text-danger\">Erro: Verifique os valores dos campos.</li>";
            }
            errorsHtml += "</ul>";
            var errorsContainer = $("#errors-container");
            errorsContainer.empty(); // Limpa qualquer mensagem de erro anterior.
            errorsContainer.append(errorsHtml);
            errorsContainer.show();
            $("#btn-submit").prop("disabled", false);
        }
      });
    }, 1500);
  });


});
