onLoad(($) => {
  const verifyAllNodes = $('.JS-ticket-check-all');
  const verifySingleNodes = $('.JS-ticket-check-single');

  if (!verifyAllNodes.length && !verifySingleNodes.length) return;

  $('.JS-perform-action-button').attr({ readonly: 'readonly', disabled: true });

  const findDataForTicket = function(checkbox) {
    checkboxData = $(checkbox).data();

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `/monitor/tickets/${checkboxData.id}/verify_ticket?ticket_type=${checkboxData.ticketType}`,
      success: function(data) {
        toggleCheckBoxesFromSingle(checkbox, data);
      }
    });
  }

  const toggleCheckBoxesFromSingle = function(checkbox, permissions) {
    $(checkbox).data('permissions', permissions);

    checkeds = $(`[data-ticket-type="${$(checkbox).data().ticketType}"]:checked`);

    let canApprove = true
    let canReject = true

    for (let i = 0;i < checkeds.length; i++) {
      if (!$(checkeds[i]).data().permissions.can_approve) {
        canApprove = false;
      }

      if (!$(checkeds[i]).data().permissions.can_reject) {
        canReject = false;
      }
    }

    updateButtons(canApprove, canReject, $(checkbox).data().ticketType.split("::")[1]);
  }


  $('.check-all').click(function () {
    let dataClass = this.dataset.checkclass;

    elements = $(`.${dataClass}`);

    var checked = $(this).prop('checked');

    for (let i = 0; i < elements.length; i++) {
      $(elements[i]).prop("checked", checked).click();
     
    };
    elements.trigger('click');
    $(`.JS-ticket-check-approve.${ticketType}`).removeAttr('readonly').removeAttr('disabled');
  });
  

  const updateButtons = function(canApprove, canReject, ticketType) {
    if (canApprove && checkeds.length > 0) {
      $(`.JS-ticket-check-approve.${ticketType}`).removeAttr('readonly').removeAttr('disabled');
    } else {
      $(`.JS-ticket-check-approve.${ticketType}`).attr({ readonly: 'readonly', disabled: true });
    }

    if (canReject && checkeds.length > 0) {
      $(`.JS-ticket-check-reject.${ticketType}`).removeAttr('readonly').removeAttr('disabled');
    } else {
      $(`.JS-ticket-check-reject.${ticketType}`).attr({ readonly: 'readonly', disabled: true });
    }
  }

  const refreshButtons = function(checkbox) {
    checkboxData = $(checkbox).data();

    if (checkboxData.permissions.approve !== null && checkboxData.permissions.reject !== null) {
      toggleCheckBoxesFromSingle(checkbox, checkboxData.permissions)
    } else {
      findDataForTicket(checkbox);
    }
  }

  verifySingleNodes.change(function () {
    refreshButtons(this);
  })

  for (let i = 0;i < verifySingleNodes.length; i++) {
    permissions = verifySingleNodes[i].dataset;
  }
});
