onLoad(($) => {
  const nodes = $('.JS-change-options-by-select');

  if (!nodes.length) return;

  const refreshFields = function(select) {
    const selects = JSON.parse(select.dataset.selects);
    const selected = $(select).children('option:selected').val();

    showOrHidden(selects[selected], true, []);

    for (let i = 0; i < Object.keys(selects).length; i++) {
      showOrHidden(selects[Object.keys(selects)[i]], false);
    }

    showOrHidden(selects[selected], true);
  };

  const showOrHidden = function(changable, show) {
    if(changable == undefined)
      return;

    const keys = Object.keys(changable);

    for (let i = 0; i < keys.length; i++) {
      const field = $(`#${keys[i]}`);
      const elements = changable[keys[i]];

      field.empty();

      if (show === true) {
        for (let el = 0;el < elements.length; el++) {
          field.append($("<option></option>").attr("value", elements[el][1]).text(elements[el][0]));
        }

        field.trigger('change');
      }
    }
  }

  nodes.change(function() {
    refreshFields(this);
  });

  for (let i = 0;i < nodes.length; i++) {
    refreshFields(nodes[i]);
  }
});
