onLoad(($) => {
  const nodes = $('.JS-auto-refresh');
  if (!nodes.length) return;

  setTimeout(() => {
    turbolinksSetInterval(() => {
      document.location.reload();
    }, 29000)
  }, 1000);
});
