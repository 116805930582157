onLoad(($) => {
  const nodes = $('.JS-show-fields-by-select');

  if (!nodes.length) return;

  const refreshFields = function(radio) {
    const mappings = JSON.parse(radio.dataset.mappings);
    const selected = $(radio).children('option:selected').val();

    let alreadyAffected = [];

    showOrHidden(mappings[selected], true, []);

    alreadyAffected = alreadyAffected.concat(mappings[selected]);

    for (let i = 0; i < Object.keys(mappings).length; i++) {
      if(selected !== Object.keys(mappings)[i]) {
        showOrHidden(mappings[Object.keys(mappings)[i]], false, alreadyAffected);

        alreadyAffected = alreadyAffected.concat(mappings[Object.keys(mappings)[i]])
      }
    }
  };

  const showOrHidden = function(mappings, show, alreadyAffected) {
    if(mappings == undefined)
      return;

    for (let i =0; i < mappings.length; i++) {
      if(!alreadyAffected.includes(mappings[i])) {
        const field = $(`#${mappings[i]}`);

        if(show === true) {
          if(field.hasClass('force-disable')) {
            field.show().parent().show();
          } else {
            field.removeAttr('readonly').removeAttr('disabled').show().parent().show();
          }
        } else {
          field.attr({ readonly: 'readonly', disabled: true }).hide().parent().hide();
        }
      }
    }
  }

  nodes.change(function() {
    refreshFields(this);
  });

  for (let i = 0;i < nodes.length; i++) {
    refreshFields(nodes[i]);
  }
});
