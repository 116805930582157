onLoad(($) => {
  const nodes = $('.JS-monitor-tickets-form');
  if (!nodes.length) return;


  $('.submit-button').click(function (event) {
    let tickets = getSelectedTicketsIds()
    let data = event.currentTarget.dataset;
    Swal.fire({
      title: `Por Favor, Informe a justificativa para ${data.action.toLowerCase()} (opcional)`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: `${data.action} boletas`,
      cancelButtonText: 'Fechar',
      showLoaderOnConfirm: true,
      preConfirm: (justification) => {
        return $.ajax({
          url: data.path,
          type: 'PATCH',
          data: {
            selected_tickets: tickets,
            justification: justification,
            status: data.status,
          }
        });
      },
    }).then((result) => {
      Swal.fire({
        icon: result.value.success ? 'success' : 'error',
        text: result.value.message,
      }).then(_ => {
        location.reload();
      })
    });
  });

  $('.check-all').click(function () {
    let dataClass = this.dataset.checkclass;

    elements = $(`.${dataClass}`);

    var checked = $(this).prop('checked');

    for (let i = 0; i < elements.length; i++) {
      $(elements[i]).prop("checked", checked);
    };
  });

  function getSelectedTicketsIds(status) {
    values = [];
    elements = $("input[name='selected_tickets[]']:checked");

    for (let i = 0; i < elements.length; i++) {
      values.push($(elements[i]).val());
    };
    return values;
  }
});
