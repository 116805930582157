onLoad(($) => {
  const nodes = $('.JS-show-fields-by-radio');

  if (!nodes.length) return;

  const refreshFields = function(radio) {
    const mappings = JSON.parse(radio.dataset.mappings);
    const selected = $(radio).filter(':checked').val();
    let alreadyAffected = [];
  
    if (selected === 'no_financial' || $('#ticket_private_fixed_income_asset_type option[value="nce"]').length >= 2) { 
      $('#ticket_private_fixed_income_asset_type option[value="nce"]').eq(0).remove();
    };
    
    if (selected === 'ted_tef' || selected === 'cetip' && $('#ticket_private_fixed_income_asset_type option[value="nce"]').length === 0) {
        $('#ticket_private_fixed_income_asset_type').append('<option value="nce">NCE</option>');
    };


    showOrHidden(mappings[selected], true, []);

    alreadyAffected = alreadyAffected.concat(mappings[selected]);

    for (let i = 0; i < Object.keys(mappings).length; i++) {
      if(selected !== Object.keys(mappings)[i]) {
        showOrHidden(mappings[Object.keys(mappings)[i]], false, alreadyAffected);

        alreadyAffected = alreadyAffected.concat(mappings[Object.keys(mappings)[i]])
      }
    }
  };

  const showOrHidden = function(mappings, show, alreadyAffected) {
    if(mappings == undefined)
      return;

    for (let i =0; i < mappings.length; i++) {
      if(!alreadyAffected.includes(mappings[i])) {
        const checkboxPrefix = 'checkbox_';
        const radioPrefix = 'radio_'
        const emptyString = '';
        const isCheckbox = mappings[i].match(checkboxPrefix);
        const isRadio = mappings[i].match(radioPrefix);

        let field = null;
        let event = null;

        if(isCheckbox) {
          field = $(`input[name='${mappings[i].replace(checkboxPrefix, emptyString)}']`);
          event = 'click';
        } else if (isRadio) {
          field = $(`#${mappings[i].replace(radioPrefix, emptyString)}`);
          event = 'change'
        } else {
          field = $(`#${mappings[i]}`);
          event = 'change';
        }

        let parent = field.parent().parent();

        if(show === true) {
          if(field.hasClass('force-disable')) {
            field.show().parent().show();
          } else {
            field.removeAttr('readonly').removeAttr('disabled').show().parent().show();
          }

          if (isCheckbox) {
            field.filter(':checked').trigger('change');
          } else {
            field.trigger(event);
          }

          if(parent.attr('class') && (parent.attr('class').includes('radio_buttons') || parent.attr('class').includes('col'))) {
            parent.show();
          }

          if(mappings[i].includes('_no')) {
            field.prop('checked', true);
            field.trigger('change');
          }
        } else {
          field.attr({ readonly: 'readonly', disabled: true }).hide().parent().hide();

          if(!isRadio && parent.attr('class') && (parent.attr('class').includes('radio_buttons') || parent.attr('class').includes('col'))) {
            parent.hide();
          }
        }
      }
    }
  }

  nodes.change(function() {
    refreshFields(this);
  });

  for (let i = 0;i < nodes.filter(':checked').length; i++) {
    refreshFields(nodes.filter(':checked')[i]);
  }
});
