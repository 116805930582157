onLoad(($) => {
  const nodes = $('.JS-managers');
  let manager_id = null;

  if (!nodes.length) return;

  nodes.each((_index, element) => {
    const managersPath = element.dataset.managersPath;
    const accountType = element.dataset.accountType;
    const manager = element.dataset.manager;


    $(element).select2({
      ajax: {
        url: managersPath,
        dataType: 'json',
        data: function (params) {
          var queryParameters = {
            q: params.term,
            select_2_extra: accountType
          }
          return queryParameters;
        }
      },
    });
    console.log("manger =>", typeof manager)
    if(manager != 'null' && manager != null && manager !== undefined){
      const json = JSON.parse(manager)
      const name = json['name'] + ' - ' + json['document_number']
      const id = parseInt(json['id'])
      $(element).select2("trigger", "select", {
          data: { id: id, text: name }
      });
    }

  }).on('change', function() {
    selectedValue = $(this).val();
    manager_id = selectedValue
  });

  const nodesFunds = $('.JS-funds-2');

  if (!nodesFunds.length) return;

  nodesFunds.each((_index, element) => {
    const fundsPath = element.dataset.fundsPath;
    const accountType = element.dataset.accountType;
    const fund = element.dataset.fund;

    $(element).select2({
      ajax: {
        url: fundsPath,
        dataType: 'json',
        data: function (params) {
          var queryParameters = {
            q: params.term,
            select_2_extra: accountType,
            manager_id: manager_id || null,
          }
          return queryParameters;
        }
      }
    });

    if(fund != 'null' && fund != undefined && fund != null){
      const json = JSON.parse(fund)
      const name = json['name'] + ' - ' + json['document_number']
      const id = parseInt(json['id'])
      $(element).select2("trigger", "select", {
          data: { id: id, text: name }
      });
    }
  });
})
