onLoad(($) => {
  const nodes = $('.JS-show-fields-by-checkbox');

  if (!nodes.length) return;

  const refreshFields = function(checkbox) {
    const mappings = JSON.parse(checkbox.dataset.mappings);
    const is_checked = $(checkbox).is(':checked');

    let selected = '';

    if(is_checked) {
      selected = 'checked';
    } else {
      selected = 'not_checked';
    }

    let alreadyAffected = [];

    showOrHidden(mappings[selected], true, []);

    alreadyAffected = alreadyAffected.concat(mappings[selected]);

    for (let i = 0; i < Object.keys(mappings).length; i++) {
      if(selected !== Object.keys(mappings)[i]) {
        showOrHidden(mappings[Object.keys(mappings)[i]], false, alreadyAffected);

        alreadyAffected = alreadyAffected.concat(mappings[Object.keys(mappings)[i]])
      }
    }
  };

  const showOrHidden = function(mappings, show, alreadyAffected) {
    if(mappings == undefined)
      return;

    for (let i =0; i < mappings.length; i++) {
      if(!alreadyAffected.includes(mappings[i])) {
        const checkboxPrefix = 'checkbox_';
        const emptyString = '';
        const isCheckbox = mappings[i].match(checkboxPrefix);

        let field = null;
        let event = null;

        if(isCheckbox) {
          field = $(`input[name='${mappings[i].replace(checkboxPrefix, emptyString)}']`);
          event = 'click';
        } else {
          field = $(`#${mappings[i]}`);
          event = 'change';
        }

        if(show === true) {
          if(field.hasClass('force-disable')) {
            field.show().parent().show();
          } else {
            field.removeAttr('readonly').removeAttr('disabled').show().parent().show();
          }

          if (isCheckbox) {
            field.filter(':checked').trigger('change');
          } else {
            field.trigger(event);
          }
        } else {
          field.attr({ readonly: 'readonly', disabled: true }).val('').hide().parent().hide();
        }
      }
    }
  }

  nodes.change(function() {
    refreshFields(this);
  });

  for (let i = 0;i < nodes.length; i++) {
    refreshFields(nodes[i]);
  }
});
