import "@rails/activestorage";
import "channels";
import Turbolinks from 'turbolinks';
import Rails from "@rails/ujs";

var jQuery = require("jquery")
// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import 'stylesheets/application';
import "bootstrap";
import '@fortawesome/fontawesome-free/js/all';
import "admin-lte";
import 'overlayscrollbars/js/jquery.overlayScrollbars.js';
import 'pickadate/lib/picker';
import 'pickadate/lib/picker.date';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import 'select2/dist/js/select2.js';
import 'pickadate/lib/picker';
import 'pickadate/lib/picker.date';
import 'jquery-mask-plugin/dist/jquery.mask.min';

import './utils';
import './select2';
import './datepicker';
import './masks';
import './calculate_value_by_quantity';
import './calculate_value_by_tax';
import './calculate_values_in_purchase_commitment';
import './show_fields_by_radio';
import './show_fields_by_select';
import './show_fields_by_checkbox';
import './verify_ticket_status.js';
import './quota_sovereign';
import './tickets/loan.js';
import './private_fixed_income_ted';
import './invested_funds';
import './invested_funds_passive';
import './disable_fields_by_checkbox';
import './change_options_by_select';
import './card.js';
import './delete-file-from-ticket';
import './auto_refresh.js';
import './monitor-tickets-form.js';
import './filter_selic_asset_fields.js';
import './filter_cetip_asset_fields.js';
import './funds.js';
import './managers.js';
import './managers_cutoff.js';
import './distributors_cutoff.js';
import './transfer_funds';
import './cetip_voice';
import './margin-filter-fields';
import './file_upload_name.js';
import './input_many_files.js';
import './show_cetip_voice_id.js';
import './monitor-limit-per-page.js';
import './report.js';
import './form_report.js';
import './shareholder_accounts_passive.js';

import Swal from 'sweetalert2';

Turbolinks.start();
window.Turbolinks = Turbolinks;
window.$ = $;

// set swal on window for sweetify
window.Swal = Swal

Rails.start();

$(document).on('turbolinks:load', function () {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
  });

  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover',
  });
});
