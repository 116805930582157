onLoad(($) => {
  const nodes = $('.JS-disable-fields-by-checkbox');

  if (!nodes.length) return;

  const refreshDisabledFields = function(checkbox, pageLoaded) {
    const mappings = JSON.parse(checkbox.dataset.disableMappings);
    const isChecked = $(checkbox).is(':checked');

    let selected = '';

    if(isChecked) {
      selected = 'checked';
    } else {
      selected = 'not_checked';
    }

    let alreadyAffected = [];

    enableOrDisable(mappings[selected], true, [], pageLoaded);

    alreadyAffected = alreadyAffected.concat(mappings[selected]);

    for (let i = 0; i < Object.keys(mappings).length; i++) {
      if(selected !== Object.keys(mappings)[i]) {
        enableOrDisable(mappings[Object.keys(mappings)[i]], false, alreadyAffected, pageLoaded);

        alreadyAffected = alreadyAffected.concat(mappings[Object.keys(mappings)[i]])
      }
    }
  };

  const enableOrDisable = function(mappings, enable, alreadyAffected, pageLoaded) {
    if(mappings == undefined)
      return;

    for (let i =0; i < mappings.length; i++) {
      if(!alreadyAffected.includes(mappings[i])) {
        const checkboxPrefix = 'checkbox_';
        const emptyString = '';
        const isCheckbox = mappings[i].match(checkboxPrefix);

        let field = null;
        let event = null;

        if(isCheckbox) {
          field = $(`input[name='${mappings[i].replace(checkboxPrefix, emptyString)}']`);
          event = 'click';
        } else {
          field = $(`#${mappings[i]}`);
          event = 'change';
        }

        if(enable === true) {
          if(field.hasClass('force-disable')) {
            field.show().parent().show();
          } else {
            field.removeAttr('readonly').removeAttr('disabled');
          }

          if (isCheckbox) {
            field.filter(':checked').trigger('change');
          } else {
            field.trigger(event);
          }
        } else {
          field.attr({ readonly: 'readonly', disabled: true });

          if (pageLoaded === false) {
            field.val('');
          }
        }
      }
    }
  }

  nodes.change(function() {
    refreshDisabledFields(this, false);
  });

  for (let i = 0;i < nodes.length; i++) {
    refreshDisabledFields(nodes[i], true);
  }
});
