onLoad(($) => {
  const nodes = $('.JS-monitor-limit-per-page');

  if (!nodes.length) return;

  $('[id=limit]').change(
    function() {
      let currentUrl = document.location.href;
      var url = new URL(currentUrl);
      url.searchParams.set($(this).parent().parent().attr('id')+'_per_page', $(this).val());
      document.location = url;
    });
});
