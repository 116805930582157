onLoad(($) => {
  const nodes = $('.JS-delete-file-from-ticket');

  if (!nodes.length) return;

  $("#ticket_public_fixed_income_asset_type")

  const elements = $(".delete-file");
  var filesToDelete = [];
  var inputFilestoDelete = $("#files_to_delete");
  inputFilestoDelete.val(null);

  var addFileToDeleteList = function (event) {
    let index = event.currentTarget.id.split('-')[1];
    let filename = event.currentTarget.attributes.data.value;
    filesToDelete.push(filename);
    inputFilestoDelete.val(filesToDelete.join('/'))
    $(`#table-line-${index}`).hide(500);
  };

  for (var i = 0; i < elements.length; i++) {
    $(elements[i]).on("click", addFileToDeleteList);
  }
});
