onLoad(() => {
  const $datepicker = $('.datepicker');
  const currentDate = new Date();

  $datepicker.pickadate({
    // Strings and translations
    monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro',
      'Novembro', 'Dezembro'],
    monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Agos', 'Set', 'Out', 'Nov', 'Dez'],
    weekdaysFull: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    // Buttons
    today: 'Hoje',
    clear: 'Limpar',
    close: 'Fechar',
    // Accessibility labels
    labelMonthNext: 'Próximo mês',
    labelMonthPrev: 'Mês anterior',
    labelMonthSelect: 'Selecione um mês',
    labelYearSelect: 'Selecione um ano',
    // Formats
    format: 'dd/mm/yyyy',
    editable: true,
    // Dropdown selectors
    selectYears: 300,
    selectMonths: true,
    // Date limits
    min: new Date(1900, 1, 1),
    max: new Date(currentDate.getFullYear() + 9999, currentDate.getMonth(), currentDate.getDate()),
    onOpen() {
      const $this = this.$node;
      const picker = $this.pickadate();
      const min = $this.data('min') ? $this.data('min').split('-') : '';
      const max = $this.data('max') ? $this.data('max').split('-') : '';

      if (Array.isArray(min)) {
        min[1] = String((parseInt(min[1], 10) - 1));
      }

      if (Array.isArray(max)) {
        max[1] = String((parseInt(max[1], 10) - 1));
      }

      if (min) picker.pickadate('set', { min });
      if (max) picker.pickadate('set', { max });
    },
    onClose() {
      const $this = this.$node;

      if ($this.hasClass('JS-dateGreaterThanInitial')) {
        const $initialDateNode = $this.closest('.row').find('.JS-dateLessThanFinal');
        const initialValue = new Date($initialDateNode.val().split('/').reverse());
        const currentValue = new Date($this.val().split('/').reverse());

        $initialDateNode.data('max', $this.val());
        if (currentValue < initialValue) $initialDateNode.val('');
      }
    },
  });
});
