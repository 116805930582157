onLoad(($) => {
  const nodes = $('.JS-file-upload-name');

  if (!nodes.length) return;

  $('input[type="file"]').change(function(){
    var file_name = $(this).val();
    new_name = file_name.split('\\').pop();
    document_name = 'Documento: '
    $('.upload_file_name').text(document_name + new_name);
  });

  for (let i = 0;i < nodes.filter(':checked').length; i++) {
    refreshFields(nodes.filter(':checked')[i]);
  }
});
