onLoad(($) => {
  const nodes = $('#JS-image-upload-list');

  if (!nodes.length) return;

  var image_field = $('.image-upload-field');
  var image_field_name = $(image_field).attr('name');
  $(image_field).attr('name', image_field_name + "[]");
  var idRemove = 0
  var removeButton = '<button class="btn remove" style="color: Tomato;"><i class="fas fa-trash danger"></i></button>'
  $(image_field).wrap('<div class=upload-wrapper></div>');
  $('.upload-wrapper').append('<button type="button" class="btn refreshthis" style="color: Tomato;"><i class="fas fa-trash danger"></i></button>');

  $('.refreshthis').on('click', function() {
    $('.upload-wrapper input').val('');
  });

  var addAnotherFile = function() {
    idRemove++;
    var new_image_field = $(image_field).clone();
    var new_image_name = image_field_name + "[]";
    $(new_image_field).attr('name', new_image_name);
    $(new_image_field).appendTo('#JS-image-upload-list');
    $(new_image_field).val('').wrap(`<div class=upload-wrapper-${idRemove}></div>`);

    var upload_wrapper = $(`.upload-wrapper-${idRemove}`);
    $(upload_wrapper).append(removeButton);

    $('.remove').on('click', function() {
      $(this).parent().remove();
    });
  };

  $("#JS-image-upload-list").on('change', function() {
    $(addAnotherFile)
  });
});
