onLoad(($) => {
  $(document).ready(function() {

    $(".funds-cutoff").prop("disabled", true);
    $(".distributors-cutoff").on("change", function() {

      const selectedValues = $(this).val();
      if (selectedValues && selectedValues.length > 0) {
        $(".funds-cutoff").prop("disabled", false);
      } else {
        $(".funds-cutoff").prop("disabled", true);
      }
    });
  });


  const nodes = $(".distributors-cutoff");
  let payload = [];
  let distributor_id = ""
  let funds_default = []

  if (!nodes.length) return;
  let distributors = [];
  nodes
    .each((_index, element) => {
      const distributorsPath = element.dataset.distributorsPath;
      const accountType = element.dataset.accountType;
      const distributor = element.dataset.distributor;

      $(element).select2({
        ajax: {
          url: distributorsPath,
          dataType: "json",
          data: function (params) {

            const queryParameters = {
              q: params.term,
              select_2_extra: accountType,
            };
            return queryParameters;
          },
          success: function (data) {
            distributors = data.results;
          },
        },
      });

      if (distributors.length <= 0) {
        $.ajax({
          url: distributorsPath,
          dataType: "json",
          data: {
            select_2_extra: accountType,
          },
          success: function (data) {
            distributors = data.results;
          },
        });
      }

      if (distributor != "null" && distributor != null && distributor !== undefined) {
        const json = JSON.parse(distributor);
        const name = json["name"] + " - " + json["document_number"];
        const id = parseInt(json["id"]);
        $(element).select2("trigger", "select", {
          data: { id: id, text: name },
        });
      }
    })
    .on("change", function () {
      selectedValue = $(this).val();
      distributor_id = selectedValue;
    });

  const nodesFunds = $(".funds-cutoff");

  if (!nodesFunds.length) return;

  nodesFunds.each((_index, element) => {
    const fund_distributorsPath = element.dataset.fundDistributorsPath;
    const accountType = element.dataset.accountType;
    const fund = element.dataset.fund_distributor;

    const $select = $(
      "#cutoff_extra_fund_distributor_id, #temporary_cutoff_fund_distributor_id"
    ).select2({
      ajax: {
        url: fund_distributorsPath,
        dataType: "json",
        data: function (params) {
          const queryParameters = {
            q: params.term,
            select_2_extra: accountType,
            distributor_id: distributor_id || null,
          };
          return queryParameters;
        },
        processResults: function (data) {
          const options = data.results.map(function (fund) {
            return {
              id: fund.id,
              text: fund.text,
              distributor_id: fund.distributor_id,
            };
          });

          const optionsGrouped = {};

          options.forEach(function (option) {
            if (!optionsGrouped[option.distributor_id]) {
              optionsGrouped[option.distributor_id] = {
                text: getDistributorName(option.distributor_id) + option.distributor_id,
                children: [],
              };
            }

            optionsGrouped[option.distributor_id].children.push(option);
          });

          function getDistributorName(distributor_id) {

            const name = distributors.find((i) => i.id === distributor_id);
            return name?.text;
          }

          const groups = Object.values(optionsGrouped);

          $("#btn-submit, #btn-solicitar-cutoff").click(function (e) {
            e.preventDefault();

            const distributors = $("#cutoff_extra_distributor_id, #temporary_cutoff_distributor_id").val();
            const funds = $("#cutoff_extra_fund_distributor_id, #temporary_cutoff_fund_distributor_id").val();

            const distributorFunds = {};

            if (distributors.length > 0) {
              groups.forEach((item) => {
                payload.push(item.children);
              });

              for (let i = 0; i < payload.length; i++) {
                const fundArray = payload[i];
                for (let j = 0; j < fundArray.length; j++) {
                  const fund = fundArray[j];
                  if (distributorFunds[fund.distributor_id]) {
                    distributorFunds[fund.distributor_id].funds.push(fund.id);
                  } else {
                    distributorFunds[fund.distributor_id] = {
                      distributor_id: fund.distributor_id,
                      funds: [fund.id],
                    };
                  }
                }
              }
              let distributorFundsArray = Object.values(distributorFunds);

              const arrayToNumber = funds.map(Number)
              for (let a = 0; a < distributorFundsArray.length; a++) {
                distributorFundsArray[a].funds = distributorFundsArray[a].funds.filter(fund => arrayToNumber.includes(fund))
              }


              payload = distributorFundsArray;
            } else {
              payload = funds
            }
          });
          return { results: groups };
        },
        templateResult: function (data) {
          if (!data.id) {
            return data.text;
          }

          return $("<span>" + getDistributorName(data.text) + "</span>");
        },
        templateSelection: function (data, container) {
          return $("<span>" + getDistributorName(data.text) + "</span>");
        },
      },
    });

    $select.on("select2:select", function (event) {
      const selectedData = event?.params?.data;
    });

    if (fund != "null" && fund != undefined && fund != null) {
      const json = JSON.parse(fund);
      const name = json["fund_name_distributor"] + " - " + json["document_number"];
      const id = parseInt(json["id"]);
      $(element).select2("trigger", "select", {
        data: { id: id, text: name },
      });
    }
  });

  $("#btn-submit, #btn-solicitar-cutoff").click(function (e) {
    e.preventDefault();
    setTimeout(() => {
      const time_limit = $("#cutoff_extra_time_limit, #temporary_cutoff_time_limit").val()
      const active_cd = $("#active_cd_cutoff").prop("checked");
      const justification = $("#cutoff_extra_justification, #temporary_cutoff_justification").val()

      const distributors = $("#cutoff_extra_distributor_id, #temporary_cutoff_distributor_id").val();
      const funds = $("#cutoff_extra_fund_distributor_id, #temporary_cutoff_fund_distributor_id").val();

      if (distributors.length > 0 && funds.length <=0) {

        payload = []
        for (let i = 0; i < distributors.length; i++) {
          payload.push({
            distributor_id: distributors[i]
          })
        }

      }else if (distributors.length > 0){
        // Remover objetos com distributor_id que não estão em distributors
        payload.filter(item => distributors.includes(item.distributor_id.toString()));

        // Adicionar objetos com distributor_id que estão em idsToCheck, mas não estão em data
        distributors.forEach(id => {
          const existingItem = payload.find(item => item.distributor_id === parseInt(id));
          if (!existingItem) {
            const newItem = {
              distributor_id: parseInt(id),
              funds: []
            };
            payload.push(newItem);
          }
        });
      }
      
      const distributorsFunds = JSON.stringify(payload);
      console.log(distributorsFunds);
      const currentRoute = window.location.pathname.replace(/\/new$/, "");
      $.ajax({
        url: currentRoute,
        type: "POST",
        data: JSON.stringify({ distributor_funds: payload, time_limit, justification, active_cd  }),
        contentType: "application/json",
        success: function(data) {

        },
        error: function(data) {
         
          response = data.responseJSON
            var errorsHtml = "<ul>";
            if (response){
              $.each(response, function(index, error) {
                if (error.time_limit) {
                  errorsHtml += "<li class=\"text-danger\">Horário Limite: " + error.time_limit + "</li>";
                }
                if (error.fund_distributor_id) {
                  errorsHtml += "<li class=\"text-danger\">Fundo: " + error.fund_distributor_id + "</li>";
                }
                if (error.distributor_id) {
                  errorsHtml += "<li class=\"text-danger\">Gestor: " + error.distributor_id + "</li>";
                }
                if (error.justification) {
                  errorsHtml += "<li class=\"text-danger\">Justificativa: " + error.justification+ "</li>";
                }
              });
            }else{
              errorsHtml += "<li class=\"text-danger\">Erro: Verifique os valores dos campos.</li>";
            }
            errorsHtml += "</ul>";
            var errorsContainer = $("#errors-container");
            errorsContainer.empty(); // Limpa qualquer mensagem de erro anterior.
            errorsContainer.append(errorsHtml);
            errorsContainer.show();
            $("#btn-submit").prop("disabled", false);
        }
      });
    }, 1500);
  });


});
