onLoad(($) => {
  $('.JS-select-autocomplete').select2({
    theme: 'bootstrap4',
    language: {
      noResults: function() {
        return "Nenhum resultado foi encontrado.";
      }
    },
  });
});
