$(document).ready(function () { 
  hideAll();
});

$(document).on("change", "#kind_cd", function (e) {
  console.log($("#kind_cd").val());

  if ($("#kind_cd").val() == "ticket") {
    $("#ticket_form").show();
  } else {
    $("#ticket_form").hide();
  }

  if ($("#kind_cd").val() == "transfer") {
    $("#transfer_form").show();
  } else {
    $("#transfer_form").hide();
  }
});

function hideAll() {
  $("#transfer_form").hide();
  $("#ticket_form").hide();
}