onLoad(($) => {
  const nodes = $('.JS-funds');

  if (!nodes.length) return;

  const searchBankAccounts = (val, url, field, filters = null) => {
    const fundId = $(val).children('option:selected').val();

    if (!!filters) {
      url = `${url}?${filters}`
    }

    $(`#${field}`).find('option').remove().end();

    $.ajax({
      url: url,
      type: 'GET',
      data: { fund_id: fundId, is_active: true },
      success: function (response) {
        for (let x = 0;x < response.length; x++) {
          $(`#${field}`).append($("<option />").val(response[x][1]).text(response[x][0]));
        }

        $(`#${field}`).trigger('change');
      }
    });
  };

  nodes.each((_index, element) => {
    const fundsPath = element.dataset.fundsPath;
    const bankAccountsPath = element.dataset.fundBankAccountsPath;
    const fundBankAccountField = element.dataset.fundBankAccountField;
    const accountType = element.dataset.accountType;

    $(element).select2({
      ajax: {
        url: fundsPath,
        dataType: 'json',
        data: function (params) {
          var queryParameters = {
            q: params.term,
            select_2_extra: accountType
          }

          return queryParameters;
        }
      }
    });

    $(element).on('change', function() {
      const settlementType = $('#ticket_quotum_settlement_type_sovereign:checked').val();
      const filters = settlementType == 'sovereign' ? 'q[bank_account_bank_code_eq]=033' : null
      searchBankAccounts(this, bankAccountsPath, fundBankAccountField, filters);
    });
  });
})
